import { Key, useEffect, useRef, useState } from "react";
import {
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
} from "@ionic/react";
import { Input } from "../../components/core/Input";
import { ButtonContainer } from "./ButtonContainer";
import { DeliveryEntry } from "../core/DeliveryEntry";
import { Modal } from "../core/Modal";
import { Autocomplete } from "../modals/Autocomplete";
import {
  setProductStartingInventory,
  setProductLot,
  addLotToProduct,
  removeProduct,
  removeProductLot,
} from "../../redux/delivery";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  ProductInventory as ProductInventoryInterface,
  ProductLot,
} from "../../interfaces/delivery";
import deliveryService from "../../service/deliveryService";
import service from "../../service/service";
import { numericOnly } from "../../lib";
import { getLocalizedDateString } from "../../service/helper";

interface Props {
  props: ProductInventoryInterface;
}

export const Product: React.FC<Props> = ({ props }) => {
  /* Props */
  const { product, lots, measurement, id } = props; // TODO: prolly don't need these since we're using redux now
  const productId = id; // just making this variable name clearer

  /* Redux */
  const delivery = useAppSelector((state) => state.delivery);
  const validation = useAppSelector((state) => state.validation);
  const dispatch = useAppDispatch();

  /* Refs */
  const addLotModal = useRef<HTMLIonModalElement>(null);

  /* Hooks */
  const [productLots, setProductLots] = useState<ProductLot[]>([]);
  const [newLot, setNewLot] = useState("");
  const [initialLotMeasurement, setInitialLotMeasurement] = useState<any>({});
  const [loading, setLoading] = useState(true);

  /* Local constants */
  const totalInventory = lots?.reduce(
    (acc, lot) => acc + Number(lot.measurement.added || 0),
    0
  );

  const getProductLotRemaining = (lotId: string, productLots: any) => {
    return productLots.find((lot: any) => lot.id === lotId)?.measurement
      ?.remaining;
  };

  /* Effects */
  /* This just uses a hook instead of redux since we don't support custom lot names */
  useEffect(() => {
    deliveryService
      .getLots({
        deliveryId: delivery.id,
        productId: product.id,
        loads: [delivery.source.load],
        warehouse: [delivery.source.warehouse],
        truck: [delivery.source.truck],
      })
      .then((lots) => {
        setProductLots(lots);
        setLoading(false);
        /* 
          For each product lot, on page load, we want to store a map of the initial values
          so that we can reference it in the ever-re-rendering Remaining component
          to determine if there is an overage or not
        */
        setInitialLotMeasurement(
          lots.reduce((acc: any, lot) => {
            acc[lot.id] = lot.measurement;
            return acc;
          }, {})
        );
      })
      .catch((e) => console.log(e));
  }, []);

  /*
    This is more of a warning than a validation, we just want the user to be aware that they're adding more than the total remaining
  */

  const Remaining: React.FC<{ added: number; lotId: string }> = ({
    added,
    lotId,
  }) => {
    const initialValues = initialLotMeasurement[lotId];

    return initialValues && initialValues.remaining < 0 ? (
      <div className="bg-yellow-10 text-yellow-110 py-2 px-4 mt-4 rounded-md">
        <b>Warning</b>: This lot has been over-delivered. There are{" "}
        {initialValues.remaining} units available.
      </div>
    ) : initialValues && initialValues.remaining - added < 0 ? (
      <div className="bg-yellow-10 text-yellow-110 py-2 px-4 mt-4 rounded-md">
        <b>Warning</b>: Only {initialValues.remaining} units are available.
      </div>
    ) : null;
  };

  return (
    <DeliveryEntry
      data={props}
      type="PRODUCT"
      title={`${product.name} · ${product.code}`}
      bubbleContent={
        <>
          <span>
            <span className="font-medium">Target</span>
            <span className="ml-1">{measurement?.target?.toString()}</span>
          </span>
          <span>
            <span className="font-medium">Total</span>
            <span className="ml-1">
              {Number(totalInventory) + // || measurement?.total) + // Not sure why this is here
                Number(measurement.starting) || 0}
            </span>
          </span>
        </>
      }
      deleteMessage="Are you sure you want to delete this product?"
      deleteCallback={() => {
        dispatch(removeProduct({ productId }));
        deliveryService.removeProductInventory(props);
      }}
      id={id}
    >
      <div className="ml-10 mt-5 ">
        <div className="mr-10">
          <Input
            label="Starting inventory"
            placeholder="0"
            type="number"
            inputMode="numeric"
            min="0"
            value={measurement.starting || ""}
            onChange={(e: any) =>
              dispatch(
                setProductStartingInventory({
                  id: productId,
                  value: numericOnly(e.target.value),
                })
              )
            }
          />
        </div>
        {!!lots.length && <div className="mt-5 mb-3 text-sm">Lots/Serials</div>}

        <IonList className="ion-list-product-override -ml-[60px] ">
          {lots?.map((lot: ProductLot, index: Key) => (
            <div
              className={`mb-5 ${index === lots.length - 1 ? "mb-0" : ""}`}
              key={index}
            >
              <IonItemSliding>
                <IonItem className="ion-item-product-override">
                  <div className="w-full">
                    <div className="grid grid-cols-[20px,1fr] gap-5 items-center w-[calc(100%-30px)]">
                      <div className="bg-blue-100 shadow-[0_0_0_4px_rgba(30,95,239,0.1)] h-2 w-2 rounded-full ml-[4px]"></div>
                      <div className="flex gap-5">
                        {/*Serial */}
                        <div className="flex-[1_0_calc(100%/3-20px)]">
                          <Input
                            label="Lot/Serial Number"
                            placeholder={"xxx-xxx"}
                            value={lot.serial}
                            onChange={(val) => {}}
                            disabled={true}
                          />
                        </div>

                        {/*Units Delivered */}
                        <div className="flex-[1_0_calc(100%/3-20px)]">
                          <Input
                            label="Total Units Delivered"
                            placeholder={"0"}
                            type="number"
                            inputMode="numeric"
                            min="0"
                            value={
                              lot.measurement.added || 0 > 0
                                ? lot.measurement.added
                                : ""
                            }
                            onChange={(e: any) =>
                              dispatch(
                                setProductLot({
                                  productId: productId,
                                  lotId: lot.id,
                                  key: "added",
                                  value: numericOnly(e.target.value),
                                })
                              )
                            }
                          />
                        </div>

                        {/*Amount Free */}
                        <div className="flex-[1_0_calc(100%/3-20px)]">
                          <Input
                            label={`Amount ${
                              product.class === service.DILUENT_TYPE.toString()
                                ? "Charged"
                                : "Free"
                            } (of total)`}
                            placeholder={"0"}
                            type="number"
                            inputMode="numeric"
                            min="0"
                            value={
                              lot.measurement.free || 0 > 0
                                ? lot.measurement.free
                                : ""
                            }
                            onChange={(e: any) =>
                              dispatch(
                                setProductLot({
                                  productId: productId,
                                  lotId: lot.id,
                                  key: "free",
                                  value: numericOnly(e.target.value),
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-10">
                      {Object.keys(initialLotMeasurement).length ? (
                        <Remaining
                          added={Number(lot.measurement.added || 0)}
                          lotId={lot.id}
                        />
                      ) : null}
                    </div>
                  </div>
                </IonItem>
                <IonItemOptions className="ion-item-options-product-override">
                  <IonItemOption
                    color="danger"
                    onClick={() => {
                      dispatch(removeProductLot({ productId, lotId: lot.id }));

                      /* Pouch */
                      deliveryService.removeLotFromProduct({
                        productInventory: props,
                        lot,
                      });
                    }}
                  >
                    Remove
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            </div>
          ))}
        </IonList>
        {props?.skipDelivery ? (
          <div className="py-2 px-4 mt-4 mr-2 w-full rounded-md bg-black-5">
            No Delivery
          </div>
        ) : (
          <ButtonContainer
            text="Add Lot/Serial"
            icon="add"
            onClick={async () => {
              await addLotModal.current?.present();
            }}
          />
        )}

        {validation.hasSubmitted && validation.invalidProducts.includes(id) ? (
          <div className="py-2 px-4 bg-orange-10 text-orange-100 mt-4 mr-2 rounded-md w-full">
            Please add at least 1 lot/serial to this product
          </div>
        ) : (
          <></>
        )}
        {/* Add lot modal */}
        <Modal heading="Choose lot/serial number" ref={addLotModal}>
          <Autocomplete
            label={"Lot/Serial"}
            value={newLot}
            ref={addLotModal}
            loading={loading}
            options={productLots.map((lot) => {
              return {
                value: lot.id,
                label:
                  lot.serial +
                  " (" +
                  lot.measurement.remaining +
                  " remaining" +
                  (lot?.expiration ? " | exp " + lot?.expiration : "") +
                  (lot?.dateCreated
                    ? " | added " + getLocalizedDateString(lot.dateCreated)
                    : "") +
                  ")",
                // " | truck: " +
                // lot.truck,
              };
            })}
            alreadySelected={lots.map((lot) => lot.id)}
            onChange={(value: any): void => {
              /*
                This is gross, mixing pouch, redux and hooks...Also, how do we pass id more cleanly through the modal? 
              */
              let chosenLot = productLots?.filter((p) => p.id == value)[0];
              const newLot = {
                id: value,
                serial: chosenLot.serial,
                measurement: { added: 0, free: 0 },
                truck: delivery.source.truck,
                product: productId,
                location: chosenLot.location,
                load: chosenLot.load,
              };
              setNewLot(value);
              dispatch(
                addLotToProduct({
                  lot: newLot,
                })
              );

              /*
                Persist to the DB
                This does persist automatically as soon as a value is updated (delivered or free).
                To persist immediately we just need to call the same thing as when that happens.
              */
              deliveryService.addLotToProduct({
                productInventory: props,
                lot: newLot,
              });
            }}
            onBlur={() => {}}
          />
        </Modal>
      </div>
    </DeliveryEntry>
  );
};
