import { IonSpinner } from "@ionic/react";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const Loader: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`flex justify-center items-center ${className || ""}`}>
      <IonSpinner name="crescent"></IonSpinner>
    </div>
  );
};
