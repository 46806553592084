import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { format } from "date-fns";
import {
  Delivery,
  DewarInventory,
  ProductInventory,
  ProductLot,
  Product,
} from "../interfaces/delivery";
import { productModel, lotModel } from "../models/Product";
import { customerModel } from "../models/Customer";
import { userModel } from "../models/User";
import service from "./service";
import userService from "./userService";
import { Filters } from "./interfaces/filters";
import { DeliveryNotice, Dewar } from "./interfaces/deliveryNotice";
import axios from "axios";
import dewar from "../models/Dewar";
var _ = require("lodash");

const { v4: uuidv4 } = require("uuid");
const dewarModel = require("../models/Dewar");
const appName = "ceva-delivery-service";
const {
  deliveryModel,
  dewarInventoryModel,
  productInventoryModel,
} = require("../models/Delivery");

const { activityModel } = require("../models/Activity");
const serverUrl = process.env.REACT_APP_SERVER_URL;

/**
 * Get inbox of deliveries for a given warehouse and/or truck.
 * @param warehouse The (string) warehouse KEY to filter deliveries.
 * @param truck The (string) truck KEY to filter deliveries.
 * @param customerId The (string) customer ID to filter deliveries.
 * @returns An inbox object containing a list of slim deliveries grouped by status.
 */
const getInbox = async (filters: Filters, limit: number) => {
  // TODO: used to sync here...
  return await service.getInbox(
    {
      ...filters,
    },
    limit
  );
};

const getHistory = async (filters: Filters) => {
  return await service.getHistory(filters);
};

/**
 * Gets a delivery.
 * @param id Get a delivery by ID.
 * @returns A fully exploded delivery (order) object.
 */
const getDelivery = async (id: string) => {
  let delivery = await service.getFullDelivery(id);

  if (delivery?.customer) {
    delivery["customer"].dewars = await getDewars({
      customerId: delivery?.customer?.id,
    });
  }

  return delivery;
};

const createDelivery = async (deliveryNotice: DeliveryNotice) => {
  // TODO: Move to userService
  let query = {
    "data.email": deliveryNotice.driverEmail,
    "data.realm": appName,
  };

  const users = await service.findAdvanced(userModel, query, null, 1);
  if (!users || users.length < 1) {
    throw new Error("Cannot find user: " + deliveryNotice.driverEmail);
  }

  let newId = uuidv4().substring(28);
  let user = users[0];
  let delivery = {
    id: newId,
    source: {
      ...user.source,
      truck: deliveryNotice.truck,
    },
    otherIds: {
      proForma: uuidv4().substring(28),
      orderId: newId,
    },
    customer: deliveryNotice.customerId,
    status: "READY",
    driver: {
      id: user.id,
      name: user.name,
      email: user.email,
    },
    dateCreated: new Date().toISOString(),
  };

  return await service.save(deliveryModel, delivery);
};

const finishDelivery = async (delivery: Delivery) => {
  let currentUser = await userService.getCurrentUser();
  let finishedDelivery = JSON.parse(JSON.stringify(delivery));

  finishedDelivery = {
    ...finishedDelivery,
    dateUpdated: new Date().toISOString(),
  };

  if (!finishedDelivery.activities) {
    finishedDelivery.activities = [];
  }

  finishedDelivery.activities.push({
    user: currentUser.id,
    date: new Date().toISOString(),
    status: finishedDelivery.status,
    type: "MARK_DELIVERY",
  });

  let result = await updateDelivery(finishedDelivery);

  // Use axios to Post finishedDelivery in body to /api/deliveries/id/process
  try {
    let url = `${serverUrl}/api/v1/deliveries/${finishedDelivery.id}/process`;
    let response = await axios.post(url, finishedDelivery, {
      headers: {
        Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
      },
    });
  } catch (error) {
    console.error(
      `Error posting to ${serverUrl}/api/v1/deliveries/${finishedDelivery.id}/process`,
      error
    );
  }

  return result;
};

/**
 * Fully update a delivery, including dewar and product inventories.
 * @param delivery The delivery to update
 * @returns The ID and revision of the delivery: //TODO: Hide the revision
 */
const updateDelivery = async (delivery: Delivery) => {
  if (delivery.status === "DELIVERED" || delivery.status === "CORRECTED") {
    console.log(
      "Ignoring updates on %s delivery until marked as delivered",
      delivery.status
    );
    return;
  }

  let updatedDelivery = JSON.parse(JSON.stringify(delivery));
  let currentUser = await userService.getCurrentUser();
  if (!updatedDelivery.activities) {
    updatedDelivery.activities = [];
  }

  updatedDelivery.dateUpdated = new Date().toISOString();

  if (await service.isToggled("ACTIVITY_LOGGING")) {
    service.save(activityModel, {
      objectId: updatedDelivery.id,
      object: "delivery",
      user: currentUser.id,
      dateCreated: new Date().toISOString(),
      status: updatedDelivery.status,
      type: "UPDATE",
    });
  }

  return service.updateDelivery(updatedDelivery.id, updatedDelivery);
};

const getProducts = async () => {
  return service.get(productModel, false);
};

/**
 * Get lots available for a product on a given truck (within the last 3 weeks)
 * @param productId The ID of the product for the lots to find.
 * @param truck The KEY of the truck the lots are on (like, ALTRUCK).
 * @returns A list of lots for the given product that are loaded on the given truck.
 */
const getLots = async (filters: Filters) => {
  let hideOldLots = (await service.isToggled("HIDE_OLD_LOTS")) == true;
  let query: any = {
    $or: [
      {
        "data.status": {
          $nin: ["UNLOADED"],
        },
      },
      {
        "data.status": {
          $exists: false,
        },
      },
    ],
    "data.serial": {
      $exists: true,
    },
    "data.load": {
      $gt: "",
    },
    ...(hideOldLots && {
      "data.dateCreated": {
        $gt: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 21), // 3 weeks
      },
    }),
  };

  if (filters.productId) {
    query["data.product"] = filters.productId;
  }

  if (
    filters?.product &&
    filters?.product.length > 0 &&
    filters?.product?.filter((p) => p?.length > 0)?.length > 0
  ) {
    query["data.product"] = {
      $in: filters.product,
    };
  }

  if (
    filters.truck &&
    filters.truck.length > 0 &&
    filters.truck.filter((t) => t?.length > 0)?.length > 0
  ) {
    query["data.truck"] = {
      $in: filters.truck,
    };
  }

  if (
    !query["data.truck"] && // The warehouse really doesn't matter if we know the truck.
    filters.warehouse &&
    filters.warehouse.length > 0 &&
    filters.warehouse?.filter((w) => w?.length > 0)?.length > 0
  ) {
    query["data.location"] = {
      $in: filters.warehouse,
    };
  }

  let sort = { "data.load": "asc" };
  let lots = await service.findAdvanced(lotModel, query, sort, 100); // TODO: Dont' hard code limits you moron

  /**
   * This calculates the remaining units for each lot among deliveries that are IN PROGRESS.
   * This is a very slow operation, and we should find a way to optimize it.
   * But for now we are removing it, as processed lots are still calculated correctly.
   * TODO: Optimize this with DB view?
   */
  // For each lot, find the product inventories that allocate units from the lot.
  // for (let lot of lots) {
  //   let productDetail = await service.getById(productModel, lot.product, false);
  //   lot.productDetail = productDetail;

  //   let productInventories = await service.findAdvanced(
  //     productInventoryModel,
  //     {
  //       "data.lots": {
  //         $elemMatch: {
  //           id: lot.id,
  //           load: lot.load,
  //         },
  //       },
  //       _id: { $regex: "^productInventory.*" },
  //     },
  //     undefined,
  //     100
  //   );
  //   if (productInventories.length > 0) {
  //     console.log("Found", productInventories.length, "product inventories");
  //   }
  //   // Filter the product inventories to only the matching lot inventories.
  //   let lotInventories = [];
  //   for (let productInventory of productInventories) {
  //     let matchingLot = productInventory.lots.filter(
  //       (mLot: any) => mLot.id == lot.id
  //     );

  //     lotInventories.push({
  //       ...matchingLot[0],
  //       delivery: productInventory.delivery,
  //     });
  //   }

  //   // Sum the used units from the lot inventories.
  //   lot.measurement.used = lotInventories
  //     ?.filter(
  //       (lotInventory: any) => lotInventory.delivery != filters.deliveryId
  //     )
  //     .reduce(
  //       (sum: any, lotInventory: any) =>
  //         sum + Number(lotInventory?.measurement?.added || 0),
  //       0
  //     );

  //   lot.measurement.remaining =
  //     Number(lot.measurement.starting) - Number(lot.measurement.used);
  // }
  return lots;
};

const getDetailedLots = async (lots: any) => {
  // Fetch details for each lot and combine the data as needed
  const detailedLots = await Promise.all(
    lots.map(async (lot: any) => {
      let productDetail = await service.getById(
        productModel,
        lot.product,
        false
      );

      lot.productDetail = productDetail;

      let productInventories = await service.findAdvanced(
        productInventoryModel,
        {
          "data.lots": {
            $elemMatch: {
              id: lot.id,
              //load: lot.load, <-- Ignore load as it seems lots have been loaded on various loads
            },
          },
          _id: { $regex: "^productInventory.*" },
        },
        undefined,
        100
      );

      // Filter the product inventories to only the matching lot inventories.
      let lotInventories = [];
      for (let productInventory of productInventories) {
        let matchingLot = productInventory.lots.filter(
          (mLot: any) => mLot.id == lot.id
        );

        lotInventories.push({
          ...matchingLot[0],
          delivery: productInventory.delivery,
        });
      }

      // Sum the used units from the lot inventories.
      lot.measurement.used = lotInventories.reduce(
        (sum: any, lotInventory: any) =>
          sum + Number(lotInventory?.measurement?.added || 0),
        0
      );

      lot.measurement.remaining =
        Number(lot.measurement.starting) - Number(lot.measurement.used);

      return lot;
    })
  );

  return detailedLots;
};

/*
  This gets the latest load for each truck
*/
const getCurrentLots = async (filters: Filters, sortDateKey?: string) => {
  const dateField =
    filters?.sortDateKey !== undefined
      ? `data.${filters.sortDateKey}`
      : "data.dateCreated";

  const dateFilter = {
    $gt: filters.afterDate || "2022-01-01",
    $lt: filters.beforeDate || "2049-12-31",
  };

  const showEmpty = filters?.truck_empty_status?.includes("EMPTY");
  const showNotEmpty = filters?.truck_empty_status?.includes("NOT_EMPTY");

  let query = {
    "data.load": {
      $exists: true,
      $ne: "",
    },

    [dateField]: dateFilter,
    ...(filters.truck &&
      filters.truck.length > 0 && {
        "data.truck": { $in: service.normalizeToArray(filters.truck) },
      }),
    ...(filters.warehouse &&
      filters.warehouse.length > 0 && {
        "data.location": { $in: service.normalizeToArray(filters.warehouse) },
      }),
    ...(filters.product &&
      filters.product.length > 0 && {
        "data.product": { $in: service.normalizeToArray(filters.product) },
      }),
    ...(filters.lot &&
      filters.lot.length > 0 && {
        "data.serial": { $in: service.normalizeToArray(filters.lot) },
      }),
    ...(filters?.truck_empty_status?.length &&
      showEmpty != showNotEmpty && {
        "data.measurement.remaining": showEmpty ? { $eq: 0 } : { $ne: 0 },
      }),
    ...(service.normalizeToArray(filters.truck_load_status) &&
      service.normalizeToArray(filters.truck_load_status)?.length && {
        "data.status": {
          $in: service.normalizeToArray(filters.truck_load_status),
        },
      }),
  };

  let lots = await service.findAdvanced(
    lotModel,
    query,
    {
      "data.load": "desc",
    },

    filters.limit || 100
  );

  return lots;
};

/**
 * Sort of a misnomer, but adds a lot for a given product inventory. This is maybe not the ideal parameter set, but we can update.
 * @param productInventory The ProductInventory from the delivery.
 * @param lot The product lot to add.
 * @returns I don't even know. Probably an id & rev.
 */
const addLotToProduct = async (props: {
  productInventory: ProductInventory;
  lot: ProductLot;
}) => {
  const { productInventory, lot } = props;
  let newProductInventory = JSON.parse(JSON.stringify(productInventory));
  if (!newProductInventory.lots) {
    newProductInventory.lots = [];
  }
  newProductInventory.lots.push(lot);
  return service.updateProductDelivery(
    newProductInventory.id,
    newProductInventory
  );
};

const updateLot = async (props: { lot: ProductLot }) => {
  const { lot } = props;
  return service.saveBlindly(lotModel, lot);
};

const unloadLot = async (props: { lot: ProductLot }) => {
  updateLot(props);
};

const removeLotFromProduct = async (props: {
  productInventory: ProductInventory;
  lot: ProductLot;
}) => {
  const { productInventory, lot } = props;
  let newProductInventory = JSON.parse(JSON.stringify(productInventory));
  let lots: ProductLot[] = [];
  if (productInventory.lots) {
    productInventory.lots.forEach((l) => {
      if (l.id !== lot.id) {
        lots.push(l);
      }
    });
  }
  newProductInventory.lots = lots;
  let result = await service.saveBlindly(
    productInventoryModel,
    newProductInventory
  );
  return result;
};

/**
 * Get dewars available for this customer. This includes all dewars that have been added on this customer's deliveries.
 * @param customerId The (string) ID of the customer.
 * @returns A list of dewars.
 */
const getDewars = async (filters: Filters, limit?: number) => {
  let query: any = {
    $and: [
      { _id: { $regex: "^dewar_.*" } },
      { "data.serial": { $gte: filters.afterId || "0" } },
      { "data.serial": { $ne: filters.afterId || "0" } },
    ],
  };

  if (filters.customerId) {
    query.$and.push({
      "data.customer": {
        $in: service.normalizeToArray(filters.customerId),
      },
    });
  }

  if (filters.warehouseId) {
    query.$and.push({
      "data.warehouse": {
        $in: service.normalizeToArray(filters.warehouseId),
      },
    });
  }

  if (filters.statuses) {
    query.$and.push({
      "data.status": {
        $in: service.normalizeToArray(filters.statuses),
      },
    });
  }

  let dewars = await service.findAdvanced(
    dewarModel,
    query,
    "data.serial",
    limit || 10
  );

  return dewars;
};

/*
  Get a single dewar -- we'll use this in the UI if we make an update to a dewar and want to 
  update the state with the latest result
*/
const getDewar = async (dewarId: string) => {
  return service.getById(dewarModel, dewarId, false);
};

const getDewarHistory = async (dewarId: string) => {
  return await service.findAdvanced(
    dewarInventoryModel,
    {
      "data.dewar": dewarId,
      "data.dateUpdated": {
        $gte: "2022-01-01",
      },
    },
    { "data.dateUpdated": "desc" },
    50
  );
};

/**
 * Add a dewar to this delivery. Creates the dewar if the serial is not found.
 * @param deliveryId The (string) ID of the delivery to add the dewar to.
 * @param dewar The dewar to add.
 */
const addDewarToDelivery = async (props: {
  deliveryId: string;
  dewar: Dewar;
}) => {
  const { deliveryId, dewar } = props;

  if (dewar) {
    let dewarInventory = {
      dewar: dewar,
      measurement: { starting: 0, added: 0, free: 0 },
      delivery: deliveryId,
    };
    const newDewarInventory = await service.addDewarInventory(
      dewarInventory,
      deliveryId
    );

    return await service.getDewarInventory(newDewarInventory.id);
  }
};

const addProductToDelivery = async (props: {
  deliveryId: string;
  product: Product;
}) => {
  const { deliveryId, product } = props;

  let productInventory = {
    product: product,
    measurement: { starting: 0, added: 0, free: 0 },
    delivery: deliveryId,
    lots: [],
  };
  const newProductInventory = await service.addProductInventory(
    productInventory,
    deliveryId
  );

  return await service.getProductInventory(newProductInventory.id);
};

const updateDewarInventory = async (dewarInventory: DewarInventory) => {
  service.saveBlindly(dewarInventoryModel, dewarInventory);
};

const removeDewarInventory = async (dewarInventory: DewarInventory) => {
  let inventory = await service.getById(
    dewarInventoryModel,
    dewarInventory.id,
    false
  );
  let delivery = await service.getById(
    deliveryModel,
    inventory.delivery,
    false
  );

  delivery.dewarInventories.splice(
    delivery.dewarInventories.indexOf(dewarInventory.id)
  );

  await service.saveBlindly(deliveryModel, delivery);
  await service.remove(dewarInventoryModel, inventory); // Send found "inventory" as provided one is a revision behind
};

const addOrUpdateDewar = async (dewar: Dewar) => {
  await service.saveBlindly(dewarModel, dewar);
};

const removeProductInventory = async (productInventory: ProductInventory) => {
  let inventory = await service.getById(
    productInventoryModel,
    productInventory.id,
    false
  );
  let delivery = await service.getById(
    deliveryModel,
    inventory.delivery,
    false
  );

  delivery.productInventories.splice(
    delivery.productInventories.indexOf(productInventory.id)
  );
  await service.saveBlindly(deliveryModel, delivery);
  await service.remove(productInventoryModel, inventory);
};

/* We'll return all fitlers by default, but let you exclude certain keys */

const getFilters = async (props: { exclude?: any }) => {
  const { exclude } = props;

  // Load supported filters from pouch views.
  // Could be faster if parallelized.
  let allTrucks = await service.getAggregate("filters/trucks", undefined);
  let allWarehouses = await service.getAggregate(
    "filters/warehouses",
    undefined
  );
  let allCustomers = await service.getAggregate("filters/customers", undefined);
  let allProducts = await service.getAggregate("filters/products", undefined);
  let allDrivers = await service.getAggregate("filters/drivers", undefined);
  let allLots = await service.getAggregate("filters/lots", undefined);

  let trucks = allTrucks.rows.map((row: any) => {
    return { display: row.key, value: row.key };
  });
  let warehouses = allWarehouses.rows.map((row: any) => {
    return { display: row.key, value: row.key };
  });
  let customers = allCustomers.rows.map((row: any) => {
    return { display: row.key.name, value: row.key.id };
  });
  let products = allProducts.rows.map((row: any) => {
    return {
      display: row.key.name + " · " + row.key.code,
      value: row.key.code,
    };
  });
  let truckLoadStatus = [
    { display: "Loaded", value: "LOADED" },
    { display: "Unloaded", value: "UNLOADED" },
  ];
  let truckEmptyStatus = [
    { display: "Empty", value: "EMPTY" },
    { display: "Not Empty", value: "NOT_EMPTY" },
  ];
  let dewarStatus = [
    { display: "In-Service", value: "ACTIVE" },
    { display: "Archived", value: "ARCHIVED" },
  ];
  let drivers = allDrivers.rows
    .map((row: any) => {
      return { display: row.key.name, value: row.key.email };
    })
    ?.filter((driver: any) => !driver?.value?.includes("haystack"));

  let lots = allLots.rows.map((row: any) => {
    return { display: row.key, value: row.key };
  });

  /* Since this is boolean, we are hard coding the only 2 options w/ friendly displays */
  let on_target = [
    { display: "Yes", value: true },
    { display: "No", value: false },
  ];

  let user = await userService.getCurrentUser();

  let filters = [
    {
      type: "truck",
      entries: _.uniqBy(trucks, "value"),
      value: user?.filters?.truck,
    },
    {
      type: "warehouse",
      entries: _.uniqBy(warehouses, "value"),
      value: user?.filters?.warehouse,
    },
    {
      type: "customer",
      entries: _.uniqBy(customers, "value"),
      value: user?.filters?.customer,
    },
    {
      type: "product",
      entries: _.uniqBy(products, "value"),
      value: user?.filters?.product,
    },
    {
      type: "lot",
      entries: _.uniqBy(lots, "value"),
      value: user?.filters?.lot,
    },
    {
      type: "driver",
      entries: _.uniqBy(drivers, "value"),
      value: user?.filters?.driver,
    },
    {
      type: "on_target",
      entries: _.uniqBy(on_target, "value"),
      value: user?.filters?.on_target,
    },
    {
      type: "truck_load_status",
      entries: _.uniqBy(truckLoadStatus, "value"),
      value: user?.filters?.truck_load_status,
    },
    {
      type: "truck_empty_status",
      entries: _.uniqBy(truckEmptyStatus, "value"),
      value: user?.filters?.truck_empty_status,
    },
    {
      display: "Status",
      type: "dewar_status",
      entries: _.uniqBy(dewarStatus, "value"),
      value: user?.filters?.dewar_status,
    },
  ];

  /* If we exclude, filter your filters with a filter 🤯 */
  return filters.filter((filter) => !exclude?.includes(filter.type));
};

const getCustomers = async (includeAllKeys = false) => {
  let custs = await service.findAdvanced(customerModel, {}, null, 500);
  return custs.map((cust: any) => {
    if (includeAllKeys) return cust;
    else
      return {
        display: cust.internalName,
        value: cust.id,
      };
  });
};

const updateCustomer = async (customer: any) => {
  return await service.updateCustomer(customer);
};

const destroy = async () => {
  return service.destroy();
};

function getDays(startDate: Date, endDate: Date) {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate).toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const aggregateDeliveries = async (props: {
  startDate: string;
  endDate: string;
  filters: any;
}) => {
  const { startDate, endDate, filters } = props;

  if (!filters) {
    return Error(
      "No filters provided, please include filters even if they are empty"
    );
  }

  let flattenedFilters = filters.reduce((acc: any, item: any) => {
    acc[item.type] = item.selectedValues;
    return acc;
  }, {});
  flattenedFilters.statuses = ["DELIVERED", "CORRECTED"];

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get a slim version of filtered deliveries
  let finder = {
    query: {
      "data.dateDelivered": {
        $gte: zonedTimeToUtc(startDate, userTimeZone),
        $lte: zonedTimeToUtc(endDate, userTimeZone),
      },
    },
    fields: ["data.dateDelivered", "_id", "data.metTarget"],
  };

  let filter: any = await service.getFilter(flattenedFilters);
  let filteredDeliveries: any = await service.findAdvancedWithFields(
    deliveryModel,
    { ...finder.query, ...filter },
    finder.fields,
    "data.dateDelivered",
    10000
  );

  // Aggregate into counts of deliveries by day
  let aggregatedDeliveries = filteredDeliveries
    .map((d: any) => {
      const date = utcToZonedTime(new Date(d.dateDelivered), userTimeZone);
      const key = format(date, "yyyy-MM-dd");
      return {
        key: key,
        value: {
          total: 1,
          onTarget: d.metTarget || 0,
        },
      };
    })
    .reduce((acc: any, cur: any) => {
      let key = cur.key;
      if (!acc[key]) {
        acc[key] = {
          total: 0,
          onTarget: 0,
        };
      }
      acc[key] = {
        total: acc[key].total + cur.value.total,
        onTarget: acc[key].onTarget + cur.value.onTarget,
      };
      return acc;
    }, {});

  // Reformat into array of key value objects for charting
  const outputArray = [];

  for (const key in aggregatedDeliveries) {
    if (aggregatedDeliveries.hasOwnProperty(key)) {
      const obj = {
        day: key,
        total: aggregatedDeliveries[key].total,
        onTarget: aggregatedDeliveries[key].onTarget,
      };
      outputArray.push(obj);
    }
  }

  return { rows: outputArray };
};

const updateProductInventory = async (productInventory: ProductInventory) => {
  service.saveBlindly(productInventoryModel, productInventory);
};

const getDeliveriesByLot = async (lotId: string) => {
  let view = await service.getView("views/deliveries_by_lot", [lotId]);
  let deliveryIds = await view.rows.map((row: any) => row.value);
  let query = {
    "data.docId": { $in: deliveryIds },
    "data.dateCreated": {
      $gte: new Date("2022-01-01"),
    },
  };

  let deliveries = await service.findAdvanced(
    deliveryModel,
    query,
    { "data.dateCreated": "desc" },
    25
  );

  return Promise.all(
    deliveries.map((d: any) => {
      return getDelivery(d.id).then((delivery: any) => {
        delivery.unitsDelivered = delivery.productInventories.reduce(
          (sum: any, productInventory: any) =>
            sum +
            Number(
              productInventory.lots
                .filter((lot: any) => lot.id === lotId)
                .reduce(
                  (sum: any, lot: any) => sum + Number(lot.measurement.added),
                  0
                )
            ),
          0
        );
        return delivery;
      });
    })
  );
};

const exportDeliveries = async (ids: any) => {
  let currentUser = await userService.getCurrentUser();
  let result = null;
  try {
    let url = `${serverUrl}/api/v1/deliveries/export`;
    let response = await axios.post(
      url,
      { ids, user: currentUser?.email },
      {
        headers: {
          Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
        },
      }
    );
    result = { data: response.data, status: response.status };
  } catch (error: any) {
    console.error(
      `Error posting to ${serverUrl}/api/v1/deliveries/export`,
      error
    );

    result = { data: error?.response?.data, status: error?.response.status };
  }

  return result;
};

const exportDewars = async (ids: any) => {
  let currentUser = await userService.getCurrentUser();
  let result = null;
  try {
    let url = `${serverUrl}/api/v1/dewars/export`;
    let response = await axios.post(
      url,
      { ids, user: currentUser?.email },
      {
        headers: {
          Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
        },
      }
    );
    result = { data: response.data, status: response.status };
  } catch (error: any) {
    console.error(`Error posting to ${serverUrl}/api/v1/dewars/export`, error);

    result = { data: error?.response?.data, status: error?.response.status };
  }

  return result;
};

const getTrucks = async () => {
  let res: any = await service.getAggregate("filters/trucks", undefined);
  return await res.rows.map((row: any) => {
    return row.key;
  });
};

const deliveryService = {
  getInbox,
  getHistory,
  getDelivery,
  createDelivery,
  finishDelivery,
  updateDelivery,
  getProducts,
  addDewarToDelivery,
  addProductToDelivery,
  getDewars,
  getDewar,
  getDewarHistory,
  updateDewarInventory,
  updateProductInventory,
  removeDewarInventory,
  removeProductInventory,
  getLots,
  getDetailedLots,
  getCurrentLots,
  getDeliveriesByLot,
  addLotToProduct,
  updateLot,
  unloadLot,
  removeLotFromProduct,
  getFilters,
  getCustomers,
  updateCustomer,
  aggregateDeliveries,
  addOrUpdateDewar,
  destroy,
  exportDeliveries,
  exportDewars,
  getTrucks,
};

export default deliveryService;
