import { Capacitor } from "@capacitor/core";
import { Delivery, Product } from "../interfaces/delivery";
import { Filters } from "./interfaces/filters";
import PouchDB from "pouchdb";
import RelationalPouch from "relational-pouch";
import cordovaSqlitePlugin from "pouchdb-adapter-cordova-sqlite";
import PouchdbFind from "pouchdb-find";
import { mapById } from "./helper";
import userService from "./userService";
import _ from "lodash";
import * as Sentry from "@sentry/react";

const DILUENT_TYPE = 909;

const dewarModel = require("../models/Dewar");
const { customerModel } = require("../models/Customer");
const { productModel, lotModel } = require("../models/Product");
const { userModel } = require("../models/User");
const { appModel } = require("../models/App");
const { authModel } = require("../models/Auth");
const { activityModel } = require("../models/Activity");
const appName = "ceva-delivery-service";
const { v4: uuidv4 } = require("uuid");

const {
  deliveryModel,
  dewarInventoryModel,
  productInventoryModel,
} = require("../models/Delivery");
var dbsource: any;
var remote: any;
var liveSync: any;
var db: any;
let authDbSource: any;
let authDb: any;

PouchDB.plugin(RelationalPouch);
PouchDB.plugin(PouchdbFind);
PouchDB.plugin(cordovaSqlitePlugin);

const platform = Capacitor.getPlatform();

/* 
  Realtime DB needs the most updated export if the user logs out/back in 
  if we use a callback, we can supply that!
*/
const getDbSource = () => {
  return dbsource;
};

const getLiveSync = () => {
  return liveSync;
};

const init = () => {
  dbsource = new PouchDB(process.env.REACT_APP_POUCH_NAME, {
    adapter: platform !== "web" ? "cordova-sqlite" : "",
    auto_compaction: true,
  });
  db = dbsource.setSchema([
    dewarModel,
    customerModel,
    userModel,
    productModel,
    lotModel,
    deliveryModel,
    dewarInventoryModel,
    productInventoryModel,
    appModel,
    activityModel,
  ]).rel;

  /* Create a separate local DB to store user auth tokens */
  authDbSource = new PouchDB("c-user", {
    adapter: platform !== "web" ? "cordova-sqlite" : "",
    auto_compaction: true,
  });

  authDb = authDbSource.setSchema([authModel]).rel;
};

const destroy = async () => {
  console.log("❌ Destroying DB...");
  try {
    /* Make sure the DB exists before trying to destroy it, to prevent errors from showing up in sentry */
    const dbInfo = await dbsource.info();
    if (dbInfo?.db_name) {
      await dbsource.destroy().then((r: any) => console.log("DESTROY", r));
    }
  } catch (e) {
    console.log("Database does not exist or there was an error: ", e);
  }
  init();
};

// async function getTotalSeq(db: any) {
//   console.log("Getting db info...");
//   const startTime = new Date().getTime();
//   const info = await db.info();
//   const endTime = new Date().getTime();
//   const duration = endTime - startTime;
//   console.log(`Got info in ${duration}ms`, info);
//   return info.update_seq;
// }

const syncLive = async (
  email: string,
  password: string,
  callback: Function
) => {
  let REMOTE_DB = process.env.REACT_APP_POUCH_URL?.replaceAll(
    "username",
    email?.replaceAll("@", "%40")
  )?.replaceAll("password", password);

  //remote = new PouchDB(REMOTE_DB);

  if (db !== null && Object.keys(db)) {
    console.log("⚡️ LIVE Syncing DB...");
    let error: any = undefined;
    liveSync = dbsource
      .sync(REMOTE_DB, {
        live: true,
        retry: true,
        continuous: true,
      })
      // Can't do this because it's hammers the remote DB
      // .on("change", async (change: any) => {
      //   getTotalSeq(remote).then((total) => {
      //     callback({ type: "change", change, total });
      //   });
      // })
      // .on("paused", async (change: any) => {
      //   getTotalSeq(remote).then((total) => {
      //     callback({ type: "pause", change: null, total });
      //   });
      // })
      .on("error", async (err: any) => {
        console.log("Error with live syncing. Signing out.", err);
        await destroy();
        await userService.signout();
        callback(err);
      });
  }
};

// TODO: explain better
const syncOnce = async (
  email: string,
  password: string,
  callback: Function
) => {
  if (db !== null && Object.keys(db)) {
    console.log("Syncing DB...");
    let REMOTE_DB = process.env.REACT_APP_POUCH_URL?.replaceAll(
      "username",
      email?.replaceAll("@", "%40")
    )?.replaceAll("password", password);

    let error: any = undefined;
    // Do initial sync, then subscribe to live updates after complete.
    dbsource
      .sync(REMOTE_DB, {
        live: false,
        retry: true,
        continuous: false,
      })
      .on("complete", async (info: any) => {
        console.log("Initial sync complete.");
      })
      .on("error", async (err: any) => {
        console.log("Error with syncing. Signing out.", err);
        await destroy();
        await userService.signout();
        callback(err);
      })
      .then(() => {
        callback();
      });
  }
};

init();

/*
  Helper for $in queries that take a string or an array 
*/
const normalizeToArray = (input: any) =>
  Array.isArray(input) ? input : [input];

/**
 * This applies default filters for the inbox logic, combining them with input filters
 * @param filters Input filters
 * @returns A query for PouchDB
 */
const getFilter = async (filters: Filters) => {
  /* Support a dynamic date sort key, or fallback to dateCreated */
  const dateField =
    filters?.sortDateKey !== undefined
      ? `data.${filters.sortDateKey}`
      : "data.dateCreated";

  const dateFilter = {
    $gt: filters.afterDate || "2022-01-01",
    $lt: filters.beforeDate || "2999-12-31",
  };

  let deliveryIds: string[] = [];
  if (filters.product && filters.product.length > 0) {
    let deliveryIdsByProduct: any = await getAggregate(
      "filters/deliveriesByProduct",
      normalizeToArray(filters.product)
    );

    if (deliveryIdsByProduct.rows.length === 0) {
      deliveryIds.push("NO_DELIVERIES");
    }
    deliveryIdsByProduct.rows.forEach((r: any) => {
      deliveryIds = deliveryIds.concat(r.value);
    });
  }

  if (filters.lot && filters.lot.length > 0) {
    let deliveryIdsByLot: any = await getAggregate(
      "filters/deliveriesByLot",
      normalizeToArray(filters.lot)
    );

    if (deliveryIdsByLot.rows.length === 0) {
      deliveryIds.push("NO_DELIVERIES");
    }

    deliveryIdsByLot.rows.forEach((r: any) => {
      r?.value?.forEach((v: any) => {
        deliveryIds = deliveryIds.concat(v?.delivery);
      });
    });
  }

  /* $gte, $lte aren't supported -- so if before date exists, we ignore after date and vis-versa */
  /* ^^ this isn't true? */
  let query = {
    [dateField]: dateFilter,
    ...(filters.truck &&
      filters.truck.length > 0 && {
        "data.source.truck": { $in: normalizeToArray(filters.truck) },
      }),
    ...(filters.warehouse &&
      filters.warehouse.length > 0 && {
        "data.source.warehouse": {
          $in: normalizeToArray(filters.warehouse),
        },
      }),
    ...(filters.on_target &&
      filters.on_target.length > 0 && {
        "data.metTarget": {
          $in: normalizeToArray(filters.on_target),
        },
      }),
    ...(filters.customer &&
      (filters.customer.length > 0 || filters.customerId) && {
        "data.customer": {
          $in: normalizeToArray(filters.customer || filters.customerId),
        },
      }),
    ...(deliveryIds &&
      deliveryIds.length > 0 && {
        "data.otherIds.orderId": {
          $in: normalizeToArray(deliveryIds),
        },
      }),
    ...(filters.driver &&
      filters.driver.length > 0 && {
        "data.driver.email": {
          $in: normalizeToArray(filters.driver),
        },
      }),
    "data.status": {
      $in: filters.statuses ? filters.statuses : ["IN_PROGRESS", "READY"],
    },
  };

  return query;
};

const getInbox = async (filters: Filters, limit: number) => {
  const inbox: any[] = [];
  let filter: any = await service.getFilter(filters);
  // Set date filter to be greater than 1 month ago for the inbox
  filter["data.dateCreated"] &&
    (filter["data.dateCreated"].$gt = new Date(
      new Date().setMonth(new Date().getMonth() - 1)
    ).toISOString());
  filter["data.dateCreated"] &&
    (filter["data.dateCreated"].$lt = new Date().toISOString());

  const deliveries = await findAdvanced(
    deliveryModel,
    filter,
    {
      [filters.sortDateKey !== undefined
        ? `data.${filters.sortDateKey}`
        : "data.dateCreated"]: "desc",
    },
    limit || 100 // TODO: we have paging now. Is this the right number of items to fetch?
  );

  //console.log("Found ", deliveries.length, " deliveries");

  // TODO: Even better would be to figure out how to load these with relationships or to
  // Index them in pre-materialized views in Pouch. But this is much faster.
  const customerIds = deliveries.map((d: any) => d.customer);
  const customers = await findAdvancedWithFields(
    customerModel,
    { "data.docId": { $in: customerIds } },
    ["data.name", "data.address"],
    undefined,
    customerIds.length
  );
  const customersById = mapById(customers, "id");

  for (const delivery of deliveries) {
    // TODO: Figure how to get relationships without additional deliveries and map
    const c = customersById.get(delivery.customer);
    inbox.push({
      id: delivery.id,
      title: c?.name,
      orderNumber: delivery.otherIds.orderId,
      address: _.join(
        [c?.address.line1, c?.address.city, c?.address.state],
        ", "
      ),
      status: delivery.status,
      truck: delivery.source?.truck,
      warehouse: delivery.source?.warehouse,
      customer: delivery.customer, // This is the ID
      driver: delivery.driver,
      dateCreated: delivery.dateCreated,
      dateUpdated: delivery.dateUpdated,
      dateDelivered: delivery.dateDelivered,
      unitsDelivered: delivery.unitsDelivered,
      metTarget: delivery.metTarget,
    });
  }

  return inbox;
};

const getHistory = async (filters: Filters) => {
  if (!filters.deliveryId || !filters.customerId) {
    throw new Error("Delivery ID and Customer ID are required");
  }

  let currentDelivery = await getById(deliveryModel, filters.deliveryId, false);

  let query = {
    "data.customer": filters.customerId,
    "data.status": { $in: ["DELIVERED", "CORRECTED"] },
    "data.dateDelivered": {
      $lt: currentDelivery.dateDelivered || new Date().toISOString(),
    },
  };
  let sort = { "data.dateDelivered": "desc" };

  const deliveries = await findAdvanced(deliveryModel, query, sort, 1);
  return Promise.all(deliveries.map((d: any) => getFullDelivery(d.id)));
};

const getFullDelivery = async (id: string) => {
  let fullResponse: any = await getFullDeliveryById(deliveryModel, id);
  let delivery = {
    id: fullResponse.data.docId,
    rev: fullResponse._rev,
    ...fullResponse.data,
  };

  if (delivery == undefined) {
    throw new Error("Could not find delivery with id " + id);
  }

  const dewarsById = await mapById(fullResponse.dewars, "docId");
  const dewarInsById = await mapById(fullResponse.dewarInventories, "docId");
  const productsById = mapById(fullResponse.products, "docId");
  const productInsById = await mapById(
    fullResponse.productInventories,
    "docId"
  );
  const usersById = mapById(fullResponse.users, "docId");

  delivery.customer = fullResponse.customer;
  delivery.customer.dewars = await service.find(
    dewarModel,
    "customer",
    delivery.customer.docId,
    false
  );

  if (typeof delivery.driver == "string") {
    // TODO: Only necessary while moving to driver user model.
    let driver = usersById.get(delivery.driver);
    if (driver) {
      delivery.driver = {
        id: driver.id,
        email: driver.email,
        name: driver.name,
      };
    }
  }

  let newDewarInventories: any = [];
  delivery.dewarInventories?.forEach((dewarInventoryId: string) => {
    let dewarInventory = dewarInsById.get(dewarInventoryId);
    if (!dewarInventory) {
      console.log("No dewar inventory found for", dewarInventoryId);
      return;
    }
    if (!dewarInventory.dewar) {
      dewarInventory.dewar = {};
    }
    dewarInventory.dewar = dewarsById.get(dewarInventory.dewar);

    if (!dewarInventory.measurement) {
      dewarInventory.measurement = {};
    }

    dewarInventory.measurement.percentAdded = (
      dewarInventory.measurement?.ending
        ? (100 *
            (dewarInventory.measurement.ending -
              dewarInventory.measurement.starting)) /
          dewarInventory.measurement.ending
        : 0
    ).toFixed(0);
    newDewarInventories.push(dewarInventory);
  });
  delivery.dewarInventories = newDewarInventories;

  let newProductInventories: any = [];
  let newDiluentInventories: any = [];
  delivery.productInventories
    ?.filter((pi: any) => pi != null)
    .forEach((productInventoryId: string) => {
      let productInventory = productInsById.get(productInventoryId) || {};
      productInventory.product = productsById.get(productInventory.product);

      let sum = 0;

      productInventory.lots?.forEach(
        (lot: any) =>
          (sum =
            Number(sum) +
            (lot.measurement ? Number(lot.measurement?.added) : 0))
      );
      if (!productInventory.lots) {
        productInventory.lots = [];
      }
      productInventory.measurement.total =
        Number(sum) +
        Number(
          productInventory.measurement.starting
            ? productInventory.measurement.starting
            : 0
        );

      productInventory.measurement.starting =
        productInventory.measurement.starting || 0;

      if (productInventory.product?.class != DILUENT_TYPE) {
        newProductInventories.push(productInventory);
      } else {
        newDiluentInventories.push(productInventory);
      }
    });

  // Add diluent products to end of delivery
  delivery.productInventories = [
    ...newProductInventories,
    ...newDiluentInventories,
  ];

  return delivery;
};

const getDewarInventory = async (id: string) => {
  const fullResponse = await getFullDewarInventoryById(dewarInventoryModel, id);

  let inventory = {
    id: fullResponse.data.docId,
    rev: fullResponse._rev,
    ...fullResponse.data,
  };

  inventory.dewar = fullResponse.dewar;
  return inventory;
};

const getProductInventory = async (id: string) => {
  const fullResponse = await getById(productInventoryModel, id, true);
  const inventories = mapById(fullResponse.productInventories, "id");
  const products = mapById(fullResponse.products, "id");

  const inventory = inventories.get(id);
  inventory.product = products.get(inventory.product);
  return inventory;
};

const updateDelivery = async (deliveryId: string, delivery: Delivery) => {
  if (deliveryId) {
    let dewarInventories: string[] = [];

    if (delivery.dewarInventories) {
      for (let dewarInventory of delivery.dewarInventories) {
        if (dewarInventory?.id) {
          let updated = await saveBlindly(dewarInventoryModel, dewarInventory);
          dewarInventory.id = updated?.id;
        } else {
          let added: any = await addDewarInventory(dewarInventory, deliveryId);
          dewarInventory.id = added?.id;
        }
        dewarInventories.push(dewarInventory.id);
      }
    }

    let productInventories: string[] = [];

    if (delivery.productInventories) {
      for (let productInventory of delivery.productInventories) {
        if (productInventory?.id) {
          let updated = await updateProductDelivery(
            productInventory.id,
            productInventory
          );
          productInventory.id = updated?.id;
        } else {
          let added: any = await addProductInventory(
            productInventory,
            deliveryId
          );
          productInventory.id = added?.id;
        }
        productInventories.push(productInventory.id);
      }
    }

    // Update the customer's contact emails
    if (delivery.notificationEmails) {
      let udpatedCustomer = {
        ...delivery.customer,
        contactEmails: delivery.notificationEmails,
      };

      updateCustomer(udpatedCustomer);
    }

    let newDelivery = {
      ...delivery,
      dewarInventories: dewarInventories,
      productInventories: productInventories,
      customer: delivery.customer.id,
    };

    await saveDelivery(newDelivery, false);
  }
};

const getUsers = async () => {
  const users = await find(userModel, "realm", appName, false);
  return users;
};

const isToggled = async (toggleName: string) => {
  const toggles = await find(appModel, "name", appName, false);
  return toggles[0]?.toggles[toggleName] == true;
};

/***************
GENERIC METHODS
****************/
const get = async (model: any, includeFull: boolean) => {
  let result = await db.find(model.singular);
  return includeFull ? result : result[model.plural];
};

const getByIdOld = async (model: any, id: string, includeFull: boolean) => {
  let result = await db.find(model.singular, id);
  if (includeFull) {
    return result;
  } else {
    // When "includeFull" is used, it returns all objects, necessitating a code-side lookup of the correct one.
    let map = mapById(result[model.plural], "id");
    let doc = map.get(id);
    return doc;
  }
};

const getById = async (model: any, id: string, includeFull: boolean) => {
  let result = null;
  try {
    result =
      includeFull === true
        ? await db.find(model.singular, id)
        : await getDocById(model.singular + "_2_" + id).then((r) => {
            return [
              {
                ...r.data,
                id: r.data.docId,
                rev: r._rev,
              },
            ];
          });
  } catch (error: any) {
    if (error.status === 404) {
      console.debug("Not found", model.singular, id);
    }
    result = null;
  }

  if (includeFull === true) {
    return result;
  } else {
    let doc = result?.length > 0 ? result[0] : null;
    return doc;
  }
};

const getDocById = async (id: string) => {
  return await dbsource.get(id, {
    include_docs: true,
    conflicts: true,
  });
};

const getFullDewarInventoryById = async (model: any, id: string) => {
  if (id == null) {
    return null;
  }

  let r = await getDocById(model.singular + "_2_" + id);

  if (r && r.data) {
    const dewar =
      r.data?.dewar && (await getDocById("dewar" + "_2_" + r.data.dewar));

    r["dewar"] = {
      id: dewar?.data.docId,
      rev: dewar?._rev,
      ...dewar?.data,
    };
  }

  return r;
};

const getFullDeliveryById = async (model: any, id: string) => {
  if (id == null) {
    return null;
  }

  let r = await getDocById(model.singular + "_2_" + id);

  if (r && r.data) {
    const dewarInventoriesPromises = r.data.dewarInventories
      ?.filter((di: any) => di != null)
      .map((di: any) => getDocById("dewarInventory" + "_2_" + di));
    const productInventoriesPromises = r.data.productInventories
      ?.filter((pi: any) => pi != null)
      .map((pi: any) => getDocById("productInventory" + "_2_" + pi));

    // Wait for all dewarInventory documents to be retrieved
    const dewarInventories =
      dewarInventoriesPromises && (await Promise.all(dewarInventoriesPromises));
    const productInventories =
      productInventoriesPromises &&
      (await Promise.all(productInventoriesPromises));
    const customer =
      r.data?.customer &&
      (await getDocById("customer" + "_2_" + r.data.customer));
    const users =
      r.data?.driver?.email &&
      (await find(userModel, "email", r.data.driver?.email, false));

    const dewarPromises = dewarInventories
      ?.filter((di: any) => di.data.dewar != null)
      .map((di: any) => getDocById("dewar" + "_2_" + di.data.dewar));

    const productPromises = productInventories
      ?.filter((pi: any) => pi.data.product != null)
      .map((pi: any) => getDocById("product" + "_2_" + pi.data.product));

    const lotPromises = productInventories
      ?.filter((pi: any) => pi.data.lots != null)
      .map((pi: any) =>
        pi.data.lots
          .filter((l: any) => l.id != null)
          .map((l: any) => getDocById("lot" + "_2_" + l.id))
      );

    const dewars = dewarPromises && (await Promise.all(dewarPromises));
    const products = productPromises && (await Promise.all(productPromises));
    const lots = lotPromises && (await Promise.all(lotPromises.flat()));

    r["dewarInventories"] = dewarInventories?.map((di: any) => {
      return { id: di.data.docId, rev: di._rev, ...di.data };
    });
    r["productInventories"] = productInventories?.map((pi: any) => {
      return { id: pi.data.docId, rev: pi._rev, ...pi.data };
    });
    r["customer"] = {
      id: customer?.data.docId,
      rev: customer?._rev,
      ...customer?.data,
    };
    r["users"] = users;
    r["dewars"] = dewars?.map((d: any) => {
      return { id: d.data.docId, rev: d._rev, ...d.data };
    });
    r["products"] = products?.map((p: any) => {
      return { id: p.data.docId, rev: p._rev, ...p.data };
    });
    r["lots"] = lots?.map((l: any) => {
      return { id: l.data.docId, rev: l._rev, ...l.data };
    });
  }

  return r;
};

const find = async (
  model: any,
  field: string,
  value: string,
  includeFull: boolean
) => {
  let result = await db.findHasMany(model.singular, field, value);
  return includeFull ? result : result[model.plural];
};

const findAdvanced = async (
  model: any,
  query: any,
  sort: any,
  limit: number
) => {
  return await findAdvancedWithFields(model, query, undefined, sort, limit);
};

const findAdvancedWithFields = async (
  model: any,
  query: any,
  fields: string[] | undefined,
  sort: any,
  limit: number
) => {
  query["data.docType"] = model.singular;

  if (fields) {
    fields.push("_id");
    fields.push("_rev");
    fields.push("data.docType");
    fields.push("data.docId");
  }

  //console.log("Finding", model.singular, query, sort, limit, fields);

  try {
    let result = await dbsource.find({
      selector: query,
      sort: sort ? [sort] : undefined,
      limit: limit,
      fields: fields,
    });

    return Promise.all(
      result.docs.map((d: any) => {
        let r = d.data;
        r.id = db.parseDocID(d._id).id;
        r.rev = d._rev;
        return r;
      })
    );
  } catch (e) {
    console.log("DB Search Error", e, query, sort);
    Sentry.captureException(e, {
      extra: {
        query: query,
        sort: sort,
      },
    });
  }

  return [];
};

const save = async (model: any, value: any) => {
  if (value.id == undefined) {
    value.id = uuidv4();
  }

  return await db.save(model.singular, {
    ...value,
    docType: model.singular,
    docId: value.id,
    appVersion: process.env.REACT_APP_VERSION,
    dateUpdated: value.dateUpdated || new Date().toISOString(),
  });
};

const saveDelivery = async (value: any, updateStatus: boolean) => {
  let valueCopy = {
    ...value,
  };

  if (value.id) {
    let doc = await getById(deliveryModel, value.id, false);

    if (
      (doc.status === "PROCESSING" || doc.status === "IN_PROCESS") &&
      doc.status !== valueCopy.status
    ) {
      console.error("Status update not allowed", value.id, valueCopy, doc);
      return null;
    }

    if (doc.status === "DELIVERED" || doc.status === "CORRECTED") {
      const hasProcessed = valueCopy.activities?.some(
        (activity: any) => activity.status === doc.status
      );

      if (!hasProcessed) {
        console.error(
          "Remote copy has been processed but local copy has not. Rejecting update"
        );
        saveConflictActivity(null, value);
        return null;
      }
    }

    valueCopy.rev = doc?.rev;
  } else {
    valueCopy.id = uuidv4();
  }

  let thing = await db
    .save(deliveryModel.singular, {
      ...valueCopy,
      docType: deliveryModel.singular,
      docId: valueCopy.id,
      appVersion: process.env.REACT_APP_VERSION,
      dateUpdated: valueCopy.dateUpdated || new Date().toISOString(),
    })
    .catch((e: any) => {
      if (e?.status == 409) {
        console.log("Conflict on", deliveryModel.singular, valueCopy, e);
      } else {
        console.log("Error saving", deliveryModel.singular, valueCopy, e);
      }
    });

  return thing;
};

const saveBlindly = async (model: any, value: any) => {
  let valueCopy = {
    ...value,
  };

  if (value.id) {
    let doc = await getById(model, value.id, false);
    valueCopy.rev = doc?.rev;
  } else {
    valueCopy.id = uuidv4();
  }

  let thing = await db
    .save(model.singular, {
      ...valueCopy,
      docType: model.singular,
      docId: valueCopy.id,
      appVersion: process.env.REACT_APP_VERSION,
      dateUpdated: valueCopy.dateUpdated || new Date().toISOString(),
    })
    .catch((e: any) => {
      if (e?.status == 409) {
        console.log("Conflict on", model.singular, valueCopy, e);
      } else {
        console.log("Error saving", model.singular, valueCopy, e);
      }
    });

  return thing;
};

const saveRelated = async (
  model: any,
  value: any,
  relatedModel: any,
  relatedId: string
) => {
  let savedItem = await saveBlindly(model, value);
  let result = await getById(relatedModel, relatedId, false);
  if (!result[model.plural]) {
    result[model.plural] = [];
  }
  result[model.plural].push(savedItem.id);
  await saveBlindly(relatedModel, result);
  return await getById(model, savedItem.id, false);
};

const addDewarInventory = async (dewarInventory: any, deliveryId: string) => {
  let delivery =
    deliveryId && (await getById(deliveryModel, deliveryId, false));
  let customer =
    delivery.customer &&
    (await getById(customerModel, delivery.customer, false));

  let dewar = dewarInventory.dewar;
  dewar.customer = delivery.customer;
  dewarInventory.customer = customer;
  dewar.status = "ACTIVE";

  let dbDewar = await find(
    dewarModel,
    "serial",
    dewar.serial?.toUpperCase(),
    false
  );
  if (dbDewar === undefined || dbDewar.length == 0) {
    dewar.serial = dewar.serial?.toUpperCase();
    let newDewar = await saveBlindly(dewarModel, dewar);
    dewarInventory.dewar = await getById(dewarModel, newDewar.id, false);
    dewarInventory.delivery = deliveryId;
    return await saveRelated(
      dewarInventoryModel,
      dewarInventory,
      deliveryModel,
      deliveryId
    );
  } else {
    dewar.id = dbDewar[0].id;
    dewar.rev = dbDewar[0].rev;
    await saveBlindly(dewarModel, dewar);
  }

  // Found the dewar, so update it.
  dewarInventory.dewar = dbDewar[0];
  dewarInventory.delivery = deliveryId;
  return await saveRelated(
    dewarInventoryModel,
    dewarInventory,
    deliveryModel,
    deliveryId
  );
};

const updateProductDelivery = async (
  productInventoryId: string,
  newProductInventory: any
) => {
  let productInventory = await getById(
    productInventoryModel,
    productInventoryId,
    false
  );
  productInventory.measurement.starting =
    newProductInventory.measurement?.starting;
  productInventory.lots = newProductInventory.lots;
  return await saveBlindly(productInventoryModel, productInventory);
};

const addProductInventory = async (
  productInventory: any,
  deliveryId: string
) => {
  productInventory.delivery = deliveryId;
  return await saveRelated(
    productInventoryModel,
    productInventory,
    deliveryModel,
    deliveryId
  );
};

const remove = (model: any, item: any) => {
  return db.del(model.singular, item);
};

const updateCustomer = async (customer: any) => {
  return await saveBlindly(customerModel, customer);
};

const getAggregate = async (view: string, keys: string[] | undefined) => {
  return await dbsource.query(view, {
    reduce: true,
    group: true,
    group_level: 1,
    keys,
  });
};

const getView = async (view: string, keys: string[] | undefined) => {
  return await dbsource.query(view, {
    reduce: false,
    group: false,
    keys,
    include_docs: false,
  });
};

const checkTargets = async (keys: string[] | undefined) => {
  let deliveries = await dbsource.query("aggregations/aggregate_by_day", {
    reduce: true,
    group: true,
    group_level: 1,
    keys,
  });
  let metTarget = await dbsource.query("aggregations/delivery_targets_by_day", {
    reduce: true,
    group: true,
    group_level: 1,
    keys,
  });

  let totalDeliveries = deliveries.rows.reduce(
    (a: number, b: any) => a + b.value,
    0
  );
  let totalMetTarget = metTarget.rows.reduce(
    (a: number, b: any) => a + b.value,
    0
  );

  return {
    totalMetTarget,
    totalDeliveries,
    ratio: totalMetTarget / totalDeliveries,
  };
};

const saveConflictActivity = async (currentUser: any, obj: any) => {
  let on = await isToggled("ACTIVITY_LOGGING");
  if (!on) {
    return;
  }

  return await service.save(activityModel, {
    objectId: obj?.id,
    user: currentUser?.id,
    dateCreated: new Date().toISOString(),
    type: "CONFLICT",
    status: obj?.status,
    doc: obj,
  });
};

const service = {
  getInbox,
  getHistory,
  getFullDelivery,
  getDewarInventory,
  getProductInventory,
  getFullDewarInventoryById,
  updateDelivery,
  getFilter,
  destroy,
  find,
  getById,
  get,
  findAdvanced,
  findAdvancedWithFields,
  updateProductDelivery,
  saveRelated,
  addDewarInventory,
  addProductInventory,
  saveBlindly,
  save,
  remove,
  updateCustomer,
  syncLive,
  syncOnce,
  authDb,
  dbsource,
  getDbSource,
  getLiveSync,
  getUsers,
  getAggregate,
  checkTargets,
  normalizeToArray,
  getView,
  DILUENT_TYPE,
  saveConflictActivity,
  isToggled,
};

export default service;
//export default { getInbox, getDelivery };
